import React from 'react';
import {Card} from 'react-bootstrap';

export default function MatchCard({match}){

  // const { MatchMadeId, Player1ID, Player2ID, Player1Name, Player2Name, Player1Bet, Player2Bet, betAmount, result, timeStamp} = match.matchProp

  return(
    (!match.result) ?
        <>
          { (!match.Player2Name) ?
            <></>
          :
          <div className='pb-5'>
            <h4 className='text-black'>{`${match.Player1Name} v ${match.Player2Name} (ongoing)`}</h4>
            <Card>
              <Card.Body>
                <Card.Title className='text-primary'>Player 1: {match.Player1Name}</Card.Title>

                <Card.Title className='text-danger'>Player 2: {match.Player2Name}</Card.Title>

                <Card.Text className='text-primary'>Player1 Prediction: {match.Player1Bet}</Card.Text>

                <Card.Text className='text-danger'>Player2 Prediction: {match.Player2Bet}</Card.Text>

                <Card.Text className='text-primary'>Player1 Total Prediction: </Card.Text>

                <Card.Text className='text-danger'>Player2 Total Prediction: </Card.Text>

                <Card.Text>Match Prediction: {match.betAmount}</Card.Text>

                <Card.Text>Date: {match.timeStamp}</Card.Text>

              </Card.Body>
            </Card>
          </div>
          }
        </>
        
        :
        <div className='pb-5'>
          <h4 className='text-danger'>{`${match.Player1Name} v ${match.Player2Name} (closed)` }</h4>
          <Card>
            <Card.Body>

              <Card.Title className='text-primary'>Player 1: {match.Player1Name}</Card.Title>

              <Card.Title className='text-danger'>Player 2: {match.Player2Name}</Card.Title>

              <Card.Text className='text-primary'>Player1 Prediction: {match.Player1Bet}</Card.Text>

              <Card.Text className='text-danger'>Player2 Prediction: {match.Player2Bet}</Card.Text>

              <Card.Text className='text-primary'>Player1 Total Prediction: </Card.Text>

              <Card.Text className='text-danger'>Player2 Total Prediction: </Card.Text>

              <Card.Text>Match Prediction: {match.betAmount}</Card.Text>

              <Card.Text>Result: {match.result}</Card.Text>

              <Card.Text>Date: {match.timeStamp}</Card.Text>

            </Card.Body>
          </Card>
        </div>
  )
}
