import React from 'react';
import '../App.css';
import { Row, Col, Card } from 'react-bootstrap';
// import { Link } from 'react-router-dom'

export default function Highlights () {

    return (
        // <Link to={"/product"} style={{textDecoration: 'none'}}>
        <>
        <h2 className='text-black'>Market Place</h2>
        <Row className="mt-4 mb-4">
        <Col xs={12} md={4}>
            <Card className="cardHighlight p-3">
                <Card.Body>
                    <Card.Title>
                        <h3>Awesome Chicken</h3>
                    </Card.Title>
                    <img className="container-fluid bg-none" src="https://i.ibb.co/TgvG56d/Chicken10-01.png" alt="Chicken10-01" border="0" />
                    {/* <Card.Text>
                        See more.
                    </Card.Text> */}
                </Card.Body>
            </Card>
        </Col>
        <Col xs={12} md={4} >
            <Card className="cardHighlight p-3">
                <Card.Body>
                    <Card.Title>
                        <h3>Blue Chicken</h3>
                    </Card.Title>
                    <img className="container-fluid bg-none" src="https://i.ibb.co/WnrG3K0/Chicken07-01.png" alt="Chicken07-01" border="0" />
                    {/* <Card.Text>
                        See more.
                    </Card.Text> */}
                </Card.Body>
            </Card>
        </Col>
        <Col xs={12} md={4}>
            <Card className="cardHighlight p-3">
                <Card.Body>
                    <Card.Title>
                        <h3>Pretty Chicken</h3>
                    </Card.Title>
                    <img className="container-fluid bg-none" src="https://i.ibb.co/6XGQgpS/Chicken06-01.png" alt="Chicken06-01" border="0" />
                    {/* <Card.Text>
                        Click here.
                    </Card.Text> */}
                </Card.Body>
            </Card>
        </Col>     
        </Row>

        <Row className="mt-4 mb-4">
            <Col xs={12} md={4}>
                <Card className="cardHighlight p-3">
                    <Card.Body>
                        <Card.Title>
                            <h3>Golden Wings</h3>
                        </Card.Title>
                        <img className="container-fluid bg-none" src="https://i.ibb.co/gPKtdcD/golden-wings.png" alt="Golden Wings" border="0" />
                        {/* <Card.Text>
                            Click here.
                        </Card.Text> */}
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md={4}>
                <Card className="cardHighlight p-3">
                    <Card.Body>
                        <Card.Title>
                            <h3>Chicken Wings</h3>
                        </Card.Title>
                        <img className="container-fluid bg-none" src="https://i.ibb.co/NYnY57n/golden-wings2.png" alt="Chicken Wings" border="0" />
                        {/* <Card.Text>
                            Click here.
                        </Card.Text> */}
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md={4}>
                <Card className="cardHighlight p-3">
                    <Card.Body>
                        <Card.Title>
                            <h3>+5 Player Level Booster</h3>
                        </Card.Title>
                        <img className="container-fluid bg-none" src="https://i.ibb.co/RNW4LFv/HP-potion.jpg" alt="HP-potion" border="0" />
                        {/* <Card.Text>
                            Click here.
                        </Card.Text> */}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        {/* </Link> */}
        </>
    )
}