import React from 'react';

import {Card, Col} from 'react-bootstrap';
// import {useParams, useNavigate, Link} from 'react-router-dom';
import {Link} from 'react-router-dom';
import UserContext from '../UserContext';
// import {useState, useEffect, useContext} from 'react';
import {useContext} from 'react';


export default function Profile() {

	const {user} = useContext(UserContext)

	const levelplus = (user.levelplus > user.playerlevel) ? user.levelplus : user.playerlevel;

    return (
    <div className='pt-3 pb-3'>
        <h2 className="profile-name p-1 text-primary">Welcome back {user.username}</h2>
        <Col xs={12} md={9}>
		<Card className=''>
			<Card.Body>
				{/* <Card.Title className='text-primary'>Game ID: {user.gameId}</Card.Title> */}

				<Card.Title className='text-primary'>Name: {user.firstName} {user.lastName}</Card.Title>

				<Card.Text>Email: {user.email}</Card.Text>

				<Card.Text>Mobile No: {user.mobileNo}</Card.Text>

				{(user.isAgent) ?
					<Card.Text>Referral Code / Link: <strong>{user.referral_code}</strong></Card.Text>
				:
					<></>
				}
		
				<Card.Text>Player Level: {levelplus}</Card.Text>

				{/* <Card.Text>No. of Chicken Characters: {user.chickencount}</Card.Text> */}

				<Card.Text>No. of Chicken Characters: {user.chickens.length}</Card.Text>

				<Card.Text>Experience: {user.exp}</Card.Text>

				<Card.Text>Gem: {user.gold}</Card.Text>

				<Card.Text>Diamond: {user.diamond}</Card.Text>

				<Card.Text>Win: {user.win}</Card.Text>

				<Card.Text>Loss: {user.loss}</Card.Text>

				<Card.Text>Current Prediction: {user.currentBet} diamonds</Card.Text>

				<Link className="btn btn-primary" to={`/profile/${user.id}`}>Set Prediction</Link>
				<br></br>
				<br></br>
				{(!user.referral_code) ?
				<>
					<Link className="btn btn-danger" to={`/setreferrallink/${user.id}`}>Set Referral Code</Link>
					<br></br>
					<br></br>
				</>
				:
				<></>
				}
				<Link className="btn btn-success" to={`/chicken`}>Go to My Farm</Link>
				<br></br>
				<br></br>
				<Link className="btn btn-primary" to={"/editprofile"}>Edit Profile</Link>
			</Card.Body>
    	</Card>
		</Col>
    </div>
    )
}