import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Container, Card, Button, Row, Col, CardImg } from 'react-bootstrap';
import {useParams, useNavigate, Link} from 'react-router-dom'
import UserContext from '../UserContext'
import Swal from 'sweetalert2'

export default function ProductView(product) {

	const {productId} = useParams()

	const {user} = useContext(UserContext)

	const navigate = useNavigate()

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [price, setPrice] = useState(0);
	const [imageUrl, setImageUrl] = useState("");

	const order = (productId) => {
		fetch(`${process.env.REACT_APP_API_URL}/users/order`, {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
				productId: productId,
				userId: user.id
			})
		})
		.then(response => response.json())
		.then(result => {
			if(result) {
				Swal.fire({
					title: "Success!",
					icon: "success",
					text: "You have ordered successfully!"
				})

				navigate('/product')
			} else {
				console.log(result)

				Swal.fire({
					title: "Something went wrong!",
					icon: "error",
					text: "Please try again."
				})
			}
		})
	}

	{/*<CardImg top src="https://media.npr.org/assets/img/2021/11/25/gettyimages-1351143298-e0023f4640974350830dc818ef1bb2672bb43830.jpg"/>*/}

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/product/${productId}`)
		.then(response => response.json())
		.then(result => {
			console.log(result.name)
			console.log(result.price)
			console.log(result.imageUrl)
			setName(result.name)
			setDescription(result.description)
			setPrice(result.price)
			setImageUrl(result.imageUrl)
		})
	}, [productId])

	return(
	<>
		<Container className="mt-8">
			<Row>
				<Col lg={{ span: 6, offset: 3 }}>
					<Card>
						<Card.Body className="text-center">
							<Card.Title>{name}</Card.Title>
							<Card.Subtitle>Description:</Card.Subtitle>
							<Card.Text>{description}</Card.Text>
							<Card.Subtitle>Price:</Card.Subtitle>
							{/*<CardImg top src={price}/>*/}
							<Card.Text>&#8369; {price}</Card.Text>
							{/* <Card.Subtitle>Class </Card.Subtitle>
							<Card.Text>8 am - 5 pm</Card.Text> */}
							<CardImg top src={imageUrl}/>

							{user.id !== null ? 
								<Button variant="primary" onClick={() => order(productId)}>Buy Now</Button>
								:
								<Link className="btn btn-danger btn-block" to= {"/login"} >Log in to Shop</Link>
							}
						</Card.Body>		
					</Card>
				</Col>
			</Row>
		</Container>
	</>
	)
}