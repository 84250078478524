import React from 'react';
import '../App.css';
import { Row, Col, Card } from 'react-bootstrap';

export default function PredictionCard ({match}) {

    return (
        <div className='cardSize2'>
        <h5 className='text-black'>{match.Player1Name} v {match.Player2Name} (ongoing)</h5>
        <Row className="mb-4">
        <Col xs={12} md={6}>
            <Card className="cardHighlight p-3">
                <Card.Body>
                    <Card.Title>
                        <h4 className='text-primary'>{match.Player1Name}</h4>
                    </Card.Title>
                    <img className="container-fluid bg-none" src="https://i.ibb.co/TgvG56d/Chicken10-01.png" alt="Chicken10-01" border="0" />
                    <Card.Text>
                        Total Player 1 Prediction:
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col xs={12} md={6} >
            <Card className="cardHighlight p-3">
                <Card.Body>
                    <Card.Title>
                        <h4 className='text-danger'>{match.Player2Name}</h4>
                    </Card.Title>
                    <img className="container-fluid bg-none" src="https://i.ibb.co/WnrG3K0/Chicken07-01.png" alt="Chicken07-01" border="0" />
                    <Card.Text>
                        Total Player 2 Prediction:
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        </Row>
        </div>
    )
}