import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {Form, Button} from 'react-bootstrap';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';

export default function UpdateUser() {
	const navigate = useNavigate()
	// const [username, setUsername] = useState('');
	// const [firstName, setFirstName] = useState('')
	// const [lastName, setLastName] = useState('')
	const [mobileNo, setMobileNo] = useState('')

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleSubmit = async e => {
		e.preventDefault();
		try {
		  const response = await fetch(`${process.env.REACT_APP_API_URL}/users/updateUser`, {
			method: 'POST',
			headers: {
				'Content-Type' : 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				// username: username,
				// firstName: firstName,
				// lastName: lastName,
				mobileNo: mobileNo,
				email:email,
				password: password
			})
		  });

		  const data = await response.json();
		  if (response.ok) {
			console.log(data.message);
			// Show success message
			Swal.fire({
				title: 'User profile updated successfully!',
				icon: 'success',
				text: data.message
			});
			navigate('/profile');
			// Optionally, redirect the user to another page after successful registration
		  } else {
			console.error('Error updating user:', data.message);
			// if (data.message === 'Username already exists') {
			// 	// Show alert for duplicate username
			// 	Swal.fire({
			// 		icon: 'error',
			// 		title: 'Oops...',
			// 		text: 'Username already exists in the database. Please use a different username.'
			// 	});
			if (data.message === 'Email already exists') {
				// Show alert for duplicate email
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Email already exists in the database. Please use a different email.'
				});
			} else {
				// Show error message
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'An error occurred. Please try again later.'
				});
			}
		  }
		} catch (error) {
		  console.error('Error registering user:', error.message);
		  // Show error message
		  Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'Something went wrong!'
		  });
		}
	};

	return (
		<>
		<h4>Update Profile</h4>
		<Form onSubmit={e => handleSubmit(e)}>
				{/* <Form.Group controlId="username">
			        <Form.Label className='text-primary'>User Name</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change User Name"
							name="username"
				            value={username} 
				            onChange={e => setUsername(e.target.value)}
				        />
			    </Form.Group>

				<Form.Group controlId="firstName">
			        <Form.Label className='text-primary'>First Name</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change First Name"
							name="firstName"
				            value={firstName} 
				            onChange={e => setFirstName(e.target.value)}
				        />
			    </Form.Group>

			    <Form.Group controlId="lastName">
			        <Form.Label className='text-primary'>Last Name</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change Last Name"
							name="lastName"
				            value={lastName} 
				            onChange={e => setLastName(e.target.value)}
				        />
			    </Form.Group> */}

			    <Form.Group controlId="mobileNo">
			        <Form.Label className='text-primary'>Mobile Number</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change Mobile Number"
							name="mobileNo"
				            value={mobileNo} 
				            onChange={e => setMobileNo(e.target.value)}
				        />
			    </Form.Group>

			   	<Form.Group controlId="email">
			        <Form.Label className='text-primary'>Email address</Form.Label>
				        <Form.Control 
				            type="email" 
				            placeholder="Change email address"
							name="email"
				            value={email} 
				            onChange={e => setEmail(e.target.value)}
				        />
			    </Form.Group>

	            <Form.Group controlId="password">
	            <Form.Label className='text-primary'>Password</Form.Label>
	                <Form.Control 
		                type={showPassword ?  "text" : "password"} 
		                placeholder="Change Password"
						name="password"
		                value={password} 
				        onChange={e => setPassword(e.target.value)}
	                />
					<Button
						onClick={togglePasswordVisibility}>
						{showPassword ? <FaEyeSlash /> : <FaEye />}
					</Button>
					<Form.Text className="text-muted">
			            Never share your password with anyone else.
			        </Form.Text>
	            </Form.Group>

	            {/* <Form.Group controlId="confirmPassword">
	                <Form.Label className='text-primary'>Confirm Password</Form.Label>
						{(password !== confirmPassword) ?
							<Form.Text className="text-danger">
								&nbsp;&nbsp;&nbsp;Password do not match!
			        		</Form.Text>
							:
							<></>
						}
						<Form.Control
							type={showPassword ?  "text" : "password"} 
							placeholder="Confirm Password"
							name="confirmPassword"
							value={confirmPassword}
							onChange={e => setConfirmPassword(e.target.value)}
							required
						/>
						
						<Button
							onClick={togglePasswordVisibility}>
							{showPassword ? <FaEyeSlash /> : <FaEye />}
						</Button>
	            </Form.Group> */}
				<br></br>
				<Button variant="danger" type="submit" id="submitBtn">
			        Submit
			    </Button>
			</Form>
		</>
	)
}