import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useState, useEffect, useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate, Navigate, Link } from 'react-router-dom';

import UserContext from '../UserContext';
import Swal from 'sweetalert2';
import "../styles/Login.css";

export default function Login(){

    const {user, setUser} = useContext(UserContext)

    const navigate = useNavigate()

    const [email, setEmail] = useState('')

    const [password, setPassword] = useState('')

    const [isActive, setIsActive] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

    const retrieveUser = (token) => {
        fetch(`${process.env.REACT_APP_API_URL}/users/details`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .then(result => {

            setUser({
                id: result._id,
                isAdmin: result.isAdmin
            })
        })
    }

    const refresh = () => {
        window.location.reload();
    }

    function authenticate(event){
        event.preventDefault()

        fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
        .then(response => response.json())
        .then(result => {
            if (result.message === "User is inactive") {
                Swal.fire({
                    title: 'Authentication Failed!',
                    icon: 'error',
                    text: 'User is inactive.'
                });
            } else if (typeof result.access !== "undefined") {
                localStorage.setItem('token', result.access)

                retrieveUser(result.access)

                Swal.fire({
                    title: 'Login Successful!',
                    icon: 'success',
                    text: 'Welcome to Fowl of Fury!'
                })

            } else {
                Swal.fire({
                    title: 'Authentication Failed!',
                    icon: 'error',
                    text: 'Invalid Email or password'
                })
            }
            navigate('/');
            refresh();
        })
    }

    useEffect(() => {
        if((isAdmin === true)){
            setIsAdmin(true)
        } else {
            setIsAdmin(false)
        }
    }, [isAdmin])

    useEffect(() => {
        if((email !== '' && password !== '')){
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [email, password])

    return(
        (user.id !== null || user.isActive === false) ?
            <>
                <Navigate to= {"/"} />
            </>
        :  
        // <div className='pt-3 pb-3' sm={{ span: 6, offset: 3 }} md={6} lg={6}>
        <div className="login-container">
            <div className="login-box">
            <h2>Login</h2>
            <Form onSubmit={event => authenticate(event)}>
                <Form.Group controlId="userEmail">
                    <Form.Label className='text-primary'>Email address</Form.Label>
                    <Form.Control 
                        type="email" 
                        placeholder="Enter email"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="password">
                    <Form.Label className='text-primary'>Password</Form.Label>
                    <Form.Control 
                        type={showPassword ?  "text" : "password"} 
                        placeholder="Password"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                        required
                    />
                    <Form.Text className="text-muted">
                        Never share your password with anyone else.
                    </Form.Text>
                    <br />
                    <Button
						onClick={togglePasswordVisibility}>
						{showPassword ? <FaEyeSlash /> : <FaEye />}
					</Button>
                </Form.Group>
                <br></br>
                {   isActive ?
                    <button className="login-button" variant="danger" type="submit" id="submitBtn">
                        Login
                    </button>
                    :
                    <button className="login-button1" variant="danger" type="submit" id="submitBtn" disabled>
                        Login
                    </button>
                }
                <p className="register-link">
                    Don't have an account? <Link to={"/register"} style={{ textDecoration: 'none', color: 'blue'}}>Register here</Link>
                </p>
            </Form>
            </div>
        </div>
    )
}
