import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import {useContext} from 'react';
import UserContext from '../UserContext';

export default function AppNavbar(){

	const {user} = useContext(UserContext)

	return(
		<Navbar className="p-1" bg="light" expand="lg" sticky="top">
			<img  src="/fowloffury_profile.jpg" alt="Logo" className='circle-logo'/>
			{/* <Navbar.Brand className="ml-3" as={Link} to= {"/"} >FOWL of FURY</Navbar.Brand> */}
			<Navbar.Brand className="ml-3">Fowl of Fury</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav"/>
			<Navbar.Collapse id="basic-navbar-nav" >
				<Nav className="mr-auto">
					{	(user.id) ?
						<>
							{(user.isAgent === true) ?
								<>
									{(user.isAdmin === true) ?
										<>
											<Nav.Link as={NavLink} to={"/"}>Home</Nav.Link>
											<Nav.Link as={NavLink} to={"/profile"}>Profile</Nav.Link>
											<Nav.Link as={NavLink} to={"/admin"}>Admin Dashboard</Nav.Link>
											<Nav.Link as={NavLink} to={"/diamond"}>Diamonds</Nav.Link>
											<Nav.Link as={NavLink} to={"/predict"}>Prediction</Nav.Link>
											<Nav.Link as={NavLink} to={"/history"}>Matches</Nav.Link>
											<Nav.Link as={NavLink} to={"/product"}>Market Place</Nav.Link>
											{/* <Nav.Link as={NavLink} to={"/helpdesk"}>Helpdesk</Nav.Link> */}
											<Nav.Link as={NavLink} to={"/logout"}>Logout</Nav.Link>
										</>
										:
										<>
											<Nav.Link as={NavLink} to={"/"}>Home</Nav.Link>
											<Nav.Link as={NavLink} to={"/profile"}>Profile</Nav.Link>
											<Nav.Link as={NavLink} to={"/agent"}>Dashboard</Nav.Link>
											<Nav.Link as={NavLink} to={"/diamond"}>Diamonds</Nav.Link>
											<Nav.Link as={NavLink} to={"/predict"}>Prediction</Nav.Link>
											<Nav.Link as={NavLink} to={"/history"}>Matches</Nav.Link>
											<Nav.Link as={NavLink} to={"/helpdesk"}>Helpdesk</Nav.Link>
											<Nav.Link as={NavLink} to={"/product"}>Market Place</Nav.Link>
											<Nav.Link as={NavLink} to={"/logout"}>Logout</Nav.Link>
										</>
									}
								</>
								:
								<>
										<Nav.Link as={NavLink} to={"/"}>Home</Nav.Link>
										<Nav.Link as={NavLink} to={"/profile"}>Profile</Nav.Link>
										<Nav.Link as={NavLink} to={"/diamond"}>Diamonds</Nav.Link>
										<Nav.Link as={NavLink} to={"/predict"}>Prediction</Nav.Link>
										<Nav.Link as={NavLink} to={"/history"}>Matches</Nav.Link>
										<Nav.Link as={NavLink} to={"/helpdesk"}>Helpdesk</Nav.Link>
										<Nav.Link as={NavLink} to={"/product"}>Market Place</Nav.Link>
										<Nav.Link as={NavLink} to={"/logout"}>Logout</Nav.Link>
										{/* <Nav.Link as={NavLink} to={`${process.env.REACT_APP_HOST_SITE}/predict`}>Prediction</Nav.Link> */}
								</>
							}
						</>
						:
						<>
							<Nav.Link className="d-flex justify-content-end" as={NavLink} to={"/login"}>Login</Nav.Link>
							<Nav.Link className="d-flex justify-content-end" as={NavLink} to={"/register"}>Register</Nav.Link>
						</>
					}	
				</Nav>
				{user.id && (
					<Nav className="ml-auto">
						<Navbar.Text className="text-right" style={{ color: '#333', fontWeight: 'bold' }}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Current Diamond: <span style={{ color: 'blue' }}>{user.diamond}  <ion-icon name="diamond" style={{ color: 'red' }}></ion-icon> </span>
						</Navbar.Text>
					</Nav>
				)}
			</Navbar.Collapse>
		</Navbar>
	)
}