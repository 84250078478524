import React from 'react';
import TransactionHistoryCard from '../components/TransactionHistoryCard';
import Loading from '../components/Loading';
import {useEffect, useState} from 'react';

export default function TransactionHistory() {
	const [transactions, setTransactions] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/transaction/getDoneTransactions`,{
			method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
		})
		.then(response => response.json())
		.then(result => {
			console.log(result)
			setTransactions(
				result.map(transaction => {
					return (
						<TransactionHistoryCard key={transaction._id} transaction ={transaction}/>
					)
				})
			)
			setIsLoading(false)
		})
	}, [])

	return(			
			(isLoading) ?
				<Loading/>
			:
			<>
				<h2 className='text-black'>Transaction History</h2>
                {transactions}
			</>
	)
}
