import React from 'react';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useContext } from 'react';
import UserContext from '../UserContext';

import HomeDiamondCard from '../components/HomeDiamondCard';

// import { HandRight, Glasses } from 'react-ionicons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function HomeDiamonds(){

	const { user } = useContext(UserContext)

    const [diamonds, setDiamonds] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredDiamonds, setFilteredDiamonds] = useState([]);
    const [searchMessage, setSearchMessage] = useState('');

	useEffect(() => {
        fetchDiamonds();
    }, []);

	const fetchDiamonds = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/diamond/getAllActive`, {
                method: 'GET',
            });
            const data = await response.json();
            setDiamonds(data);
        } catch (error) {
            console.error('Error fetching diamonds:', error);
        }
    };
	
	const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

	const handleSearch = () => {
        filterResults();
    };

	const filterResults = () => {
        const searchTermLower = searchTerm.toLowerCase();

        let diamondResults = diamonds;

        if (searchTerm.trim() !== '') {
            diamondResults = diamonds.filter((diamond) =>
                Object.values(diamond).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            if (diamondResults.length === 0) {
                setSearchMessage('Keyword not found');
            } else {
                setSearchMessage('');
            }
        } else {
            setSearchMessage('');
        }

        setFilteredDiamonds(diamondResults);
    };

	return(			
		(user.id ===null) ?
			<div className='mb-5 pb-5'>
				<h4 className="profile-list pt-3 pb-3">Choose your Loader or search below:</h4>
                <span><p className="pt-3 pb-3">If you don't have a loading station, click or tap the search icon <ion-icon name="arrow-forward" style={{ color: 'black' }}></ion-icon> &nbsp;
                    <button className="btn btn-light" onClick={handleSearch}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                    </p>
                    {/* <Button onClick={handleSearch}>Magnifying Glass</Button>  */}
                </span>
                <p className="profile-list pt-3">If you know the name of your loading station, search the name at the searchbar then click or tap the Search button </p>
                <div className="search-bar">
					<input
						type="text"
                        style={{ width: '230px' }}
						placeholder="Search by name or keyword, etc..."
						value={searchTerm}
						onChange={handleSearchInputChange}
					/>
                    {/* &nbsp;
                    <span>
				    Click search
                    </span> */}
                    &nbsp;
                    <span>
					<Button onClick={handleSearch}>Search</Button>
                    </span>
					<p>{searchMessage}</p>
				</div>
				<div className='cardChicken mb-3'>
					{filteredDiamonds.map((diamond) => (
						<HomeDiamondCard key={diamond._id} diamond={diamond} />
					))}
				</div>
			</div>
		:
		<>
		</>
	)
}