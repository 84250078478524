import React from 'react'
// import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function PreFooter() {
  return (
    <footer>
		<span>Terms of Service</span>
		<span>|</span>
		<span><a href="/privacypolicy.html">Privacy Policy</a></span>
		<span>|</span>
		<span>&copy; 2024 FowlofFury. All Rights Reserved</span>
	</footer>
  )
}

// export default PreFooter
