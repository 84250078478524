import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import "../App.css";

export default function HomeDiamondCard({diamond}){

  return(
    <div className='cardSize'>
    <Row className="mt-1 mb-1">
      <h5 className='text-danger'>{diamond.name}</h5>
      <Col>
        <Card>
          <Card.Body>
            <Card.Subtitle></Card.Subtitle>
            <Card.Text><strong>Description:</strong> {diamond.description}</Card.Text>
            <Card.Text><strong>Location:</strong> {diamond.diamond_location}</Card.Text>
            <Card.Text><strong>On Stock:</strong> {(diamond.onStock ? "Yes" : "No")}</Card.Text>
            <Link className="btn btn-primary" style={{fontSize: 12}} to={`/register/${diamond.agentId}`}>Register Now</Link>
          </Card.Body>
        </Card>
      </Col>
      </Row>
    </div>
  )
}
