import React from 'react';
import {Card, CardImg, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import UserContext from '../UserContext';
import {useContext} from 'react';

export default function DiamondCard({diamond}){

  const {user} = useContext(UserContext)

  return(
    <div className='cardSize'>
    <Row className="mt-1 mb-1">
      <h3 className='text-danger'>{diamond.name}</h3>
      <Col>
        <Card>
          <Card.Body>
            <Card.Subtitle></Card.Subtitle>
            <Card.Text><strong>Description:</strong> {diamond.description}</Card.Text>
            <Card.Text><strong>Location:</strong> {diamond.diamond_location}</Card.Text>
            <Card.Text><strong>Mobile No.:</strong> {diamond.mobileNo}</Card.Text>
            <Card.Text><strong>On Stock:</strong> {(diamond.onStock ? "Yes" : "No")}</Card.Text>
            {(user.isAgent === false) ?
              <Link className="btn btn-primary" to={`/buy-diamond/${diamond._id}/${user.id}`}>Buy Now</Link>
              :
              <></>
            }
            <br></br>
            <br></br>
            <Link className="btn btn-danger" to={`/sell-diamond/${diamond._id}/${user.id}`}>Trade</Link>
            <CardImg width="50%" height="50%" top src={diamond.imageUrl}/>
          </Card.Body>
        </Card>
      </Col>
      </Row>
    </div>
  )
}
