import React, { useState, useEffect, useContext } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../components/Loading';
import AgentCard from '../components/AgentCard';
import ProfileCard from '../components/ProfileCard';
import AdminBanner from '../components/AdminBanner';
import SellAgentTransactionCard from '../components/SellAgentTransactionCard';
import BuyPlayerTransactionCard from '../components/BuyPlayerTransactionCard';
import SellPlayerTransactionCard from '../components/SellPlayerTransactionCard';
import AgentTransactionCard from '../components/AgentTransactionCard';
import UserContext from '../UserContext';

export default function AdminDashboard() {
    const { user } = useContext(UserContext);
    const [profiles, setProfiles] = useState([]);
    const [agents, setAgents] = useState([]);
    const [allagents, setAllAgents] = useState([]);
    const [allplayers, setAllPlayers] = useState([]);
    const [sellplayertransactions, setSellPlayerTransactions] = useState([]);
    const [sellagenttransactions, setSellAgentTransactions] = useState([]);
    const [buyplayertransactions, setBuyPlayerTransactions] = useState([]);
    const [buyagenttransactions, setBuyAgentTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProfiles, setFilteredProfiles] = useState([]);
    const [filteredAgents, setFilteredAgents] = useState([]);
    const [filteredSellPlayerTransactions, setFilteredSellPlayerTransactions] = useState([]);
    const [filteredSellAgentTransactions, setFilteredSellAgentTransactions] = useState([]);
    const [filteredBuyPlayerTransactions, setFilteredBuyPlayerTransactions] = useState([]);
    const [filteredBuyAgentTransactions, setFilteredBuyAgentTransactions] = useState([]);
    const [searchMessage, setSearchMessage] = useState('');
    //
    const [setCounts] = useState({ agentCount: 0, playerCount: 0 });

    useEffect(() => {
        if (user) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/subagent/${user.id}/count`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setCounts(data);
                } catch (error) {
                    console.error('Error fetching profiles:', error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchData();
        }
    }, [user]);

    useEffect(() => {
        fetchProfiles();
        fetchAgents();
        fetchAllAgents();
        fetchAllPlayers();
        fetchSellPlayerTransactions();
        fetchSellAgentTransactions();
        fetchBuyPlayerTransactions();
        fetchBuyAgentTransactions();
    }, []);

	// Automatic search on Searchbar
	// useEffect(() => {
    //     filterResults();
    // }, [searchTerm]);

    const fetchProfiles = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/getAgentPlayers`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setProfiles(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching profiles:', error);
        }
    };

    const fetchAgents = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/getAllAgents`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setAgents(data);
        } catch (error) {
            console.error('Error fetching agents:', error);
        }
    };

    const fetchAllAgents = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/AllAgents`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setAllAgents(data);
        } catch (error) {
            console.error('Error fetching agents:', error);
        }
    };

    const fetchAllPlayers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/getAllPlayers`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setAllPlayers(data);
        } catch (error) {
            console.error('Error fetching agents:', error);
        }
    };

    const fetchSellPlayerTransactions = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/transaction/getSellPlayerTransactions`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setSellPlayerTransactions(data);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

    const fetchSellAgentTransactions = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/transaction/getSellAgentTransactions`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setSellAgentTransactions(data);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

    const fetchBuyPlayerTransactions = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/transaction/getBuyPlayerTransactions`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setBuyPlayerTransactions(data);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

    const fetchBuyAgentTransactions = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/transaction/getBuyAgentTransactions`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setBuyAgentTransactions(data);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

	const handleSearch = () => {
        filterResults();
    };

    const handleLoaderSearch = () => {
        filterLoaderResults();
    };

    // const handlePlayerSearch = () => {
    //     filterPlayerResults();
    // };

	const filterResults = () => {
        const searchTermLower = searchTerm.toLowerCase();

        let profileResults = profiles; // Initially set to all profiles
        let agentResults = agents; // Initially set to all agents
        let sellplayertransactionResults = sellplayertransactions; // Initially set to all transactions
        let sellagenttransactionResults = sellagenttransactions;
        let buyplayertransactionResults = buyplayertransactions;
        let buyagenttransactionResults = buyagenttransactions;

        if (searchTerm.trim() !== '') {
            profileResults = profiles.filter((profile) =>
                Object.values(profile).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            agentResults = agents.filter((agent) =>
                Object.values(agent).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            buyplayertransactionResults = buyplayertransactions.filter((buyplayertransaction) =>
                Object.values(buyplayertransaction).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            buyagenttransactionResults = buyagenttransactions.filter((buyagenttransaction) =>
                Object.values(buyagenttransaction).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            sellplayertransactionResults = sellplayertransactions.filter((sellplayertransaction) =>
                Object.values(sellplayertransaction).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            sellagenttransactionResults = sellagenttransactions.filter((sellagenttransaction) =>
                Object.values(sellagenttransaction).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            if (profileResults.length === 0 && agentResults.length === 0 && buyplayertransactionResults.length === 0 && buyagenttransactionResults.length === 0 && sellplayertransactionResults.length === 0 && sellagenttransactionResults.length === 0) {
                setSearchMessage('Keyword not found');
            } else {
                setSearchMessage('');
            }
        } else {
            setSearchMessage('');
        }

        setFilteredProfiles(profileResults);
        setFilteredAgents(agentResults);
        setFilteredSellPlayerTransactions(sellplayertransactionResults);
        setFilteredSellAgentTransactions(sellagenttransactionResults);
        setFilteredBuyPlayerTransactions(buyplayertransactionResults);
        setFilteredBuyAgentTransactions(buyagenttransactionResults);
    };

    const filterLoaderResults = () => {
        const searchTermLower = searchTerm.toLowerCase();

        let agentResults = agents; // Initially set to all agents
        let sellagenttransactionResults = sellagenttransactions;
        let buyagenttransactionResults = buyagenttransactions;

        if (searchTerm.trim() !== '') {
            agentResults = agents.filter((agent) =>
                Object.values(agent).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            buyagenttransactionResults = buyagenttransactions.filter((buyagenttransaction) =>
                Object.values(buyagenttransaction).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            sellagenttransactionResults = sellagenttransactions.filter((sellagenttransaction) =>
                Object.values(sellagenttransaction).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            if (agentResults.length === 0 && buyagenttransactionResults.length === 0 && sellagenttransactionResults.length === 0) {
                setSearchMessage('Keyword not found');
            } else {
                setSearchMessage('');
            }
        } else {
            setSearchMessage('');
        }

        setFilteredAgents(agentResults);
        setFilteredSellAgentTransactions(sellagenttransactionResults);
        setFilteredBuyAgentTransactions(buyagenttransactionResults);
    };

    // const filterPlayerResults = () => {
    //     const searchTermLower = searchTerm.toLowerCase();

    //     let profileResults = profiles; // Initially set to all profiles
    //     let sellplayertransactionResults = sellplayertransactions; // Initially set to all transactions
    //     let buyplayertransactionResults = buyplayertransactions;

    //     if (searchTerm.trim() !== '') {
    //         profileResults = profiles.filter((profile) =>
    //             Object.values(profile).some((value) =>
    //                 typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
    //             )
    //         );

    //         buyplayertransactionResults = buyplayertransactions.filter((buyplayertransaction) =>
    //             Object.values(buyplayertransaction).some((value) =>
    //                 typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
    //             )
    //         );

    //         sellplayertransactionResults = sellplayertransactions.filter((sellplayertransaction) =>
    //             Object.values(sellplayertransaction).some((value) =>
    //                 typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
    //             )
    //         );

    //         if (profileResults.length === 0 && buyplayertransactionResults.length === 0 && sellplayertransactionResults.length === 0) {
    //             setSearchMessage('Keyword not found');
    //         } else {
    //             setSearchMessage('');
    //         }
    //     } else {
    //         setSearchMessage('');
    //     }

    //     setFilteredProfiles(profileResults);
    //     setFilteredSellPlayerTransactions(sellplayertransactionResults);
    //     setFilteredBuyPlayerTransactions(buyplayertransactionResults);
    // };

	// const handleSearch = () => {
    //     if (!searchTerm.trim()) {
    //         // If search term is empty, show all profiles
    //         setSearchResults([]);
    //         return;
    //     }

    //     const filteredResults = profiles.filter((profile) =>
    //         Object.values(profile).some((value) =>
    //             typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
    //         )
    //     );

    //     if (filteredResults.length === 0) {
    //         // If no matching profiles found, set search results to empty array
    //         setSearchResults([]);
    //     } else {
    //         // Set search results to filtered profiles
    //         setSearchResults(filteredResults);
    //     }
    // };

    // const handleSearch = () => {
    //     const filteredResults = profiles.filter((profile) =>
    //         profile.username.toLowerCase().includes(searchTerm.toLowerCase())
    //     );
    //     setSearchResults(filteredResults);
    // };

    return (
        isLoading ? <Loading /> :
            <>
                <AdminBanner />
                <a href="#agents" className='text-black mb-3'>Go to Loading Stations</a>
                <br />
                <a href="#players" className='text-black mb-3'>Go to Players</a>
                <Card className='mt-3'>
                    <Card.Body>
                        <Card.Title className='text-primary'>Name: {user.firstName} {user.lastName}</Card.Title>
                        <Card.Text>Email: {user.email}</Card.Text>
                        <Card.Text>All loaders: {allagents.length}</Card.Text>
                        <Card.Text>All players: {allplayers.length}</Card.Text>
                        {/* <Card.Text>Mobile No: {user.mobileNo}</Card.Text>
                        <Card.Text>Loader Level: {user.subAgentCategory}</Card.Text> */}
                        <Card.Text>No. of Loading Stations: {agents.length}</Card.Text>
                        <Card.Text>No. of Player/s - Directly under: {profiles.length}</Card.Text>
                        {/* <Card.Text>Total No. of Loader/s - All under: {counts.agentCount}</Card.Text>
                        <Card.Text>Total No. of Player/s - All under: {counts.playerCount}</Card.Text> */}
                        {/* <Card.Text>Diamonds On-Hand: {user.agentdiamond}</Card.Text> */}
                        <Card.Text>Company Diamonds on-hand: {user.agentdiamond}</Card.Text>
                        <Card.Text>Earned Diamonds: {user.earnagentdiamond}</Card.Text>
						{/* <br />
						<Link className="btn btn-success" to={`/request-agentdiamond`}>Request Diamond from Loader</Link>
						<br />
						<br />
						<Link className="btn btn-primary" to={`/sell-agentdiamond`}>Trade Diamond to Loader</Link>
						<br /> */}
						<br />
						<Link className="btn btn-success" to={`/transactionhistory`}>Transaction History</Link>
						<br />
						{/* <br />
						<Link className="btn btn-primary" to={`/editdiamond`}>Edit Diamond Market</Link> */}
                    </Card.Body>
                </Card>
                <br />
				<div className="search-bar">
                    <input className='mr-sm-2'
                        type="text"
                        style={{ width: '460px' }}
                        placeholder="Search by username, first name, last name, email, mobile no., etc..."
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
					&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button onClick={handleSearch}>Search</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button className='btn btn-success' onClick={handleLoaderSearch}>Loading Station</Button>
                    {/* &nbsp;&nbsp;&nbsp;
                    <Button className='btn btn-danger' onClick={handlePlayerSearch}>Player</Button> */}
                    <p>{searchMessage}</p>
                </div>
				<br />
                {/* <h3 className="profile-list p-1 text-success">Player Requests</h3> */}
                <div>
                    {filteredBuyPlayerTransactions.map((buyplayertransaction) => (
                        <BuyPlayerTransactionCard key={buyplayertransaction._id} transaction={buyplayertransaction} />
                    ))}
                </div>
                <div>
                    {filteredSellPlayerTransactions.map((sellplayertransaction) => (
                        <SellPlayerTransactionCard key={sellplayertransaction._id} transaction={sellplayertransaction} />
                    ))}
                </div>                
                <br />
                <h3 className="profile-list p-1 text-success">Loader Requests</h3>
                <div>
                    {filteredBuyAgentTransactions.map((buyagenttransaction) => (
                        <AgentTransactionCard key={buyagenttransaction._id} transaction={buyagenttransaction} />
                    ))}
                </div>
                <br />
                <div>
                    {filteredSellAgentTransactions.map((sellagenttransaction) => (
                        <SellAgentTransactionCard key={sellagenttransaction._id} transaction={sellagenttransaction} />
                    ))}
                </div>
                <br />
                <h3 id="agents" className="profile-list p-1 text-danger">List of Loading Stations</h3>
                <div>
                    {filteredAgents.map((agent) => (
                        <AgentCard key={agent._id} agent={agent} />
                    ))}
                </div>
                <br />
                {/* <h3 id="players" className="profile-list p-1 text-danger">List of Players</h3> */}
                <div>
                    {filteredProfiles.map((profile) => (
                        <ProfileCard key={profile._id} profile={profile} />
                    ))}
                </div>
                {/* <h3 id="players" className="profile-list p-1 text-danger">List of Players</h3>
                {searchResults.length > 0 ? (
                    searchResults.map((profile) => (
                        <ProfileCard key={profile._id} profile={profile} />
                    ))
                ) : (
					<p>{searchTerm.trim() ? 'Profile not found' : 'Enter a search term'}</p>
                )}
				{profiles.map((profile) => (
                        <ProfileCard key={profile._id} profile={profile} />
                    ))} */}
            </>
    );
}
