import React from 'react';
import {Card, CardImg, Col, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'

export default function ProductCard(product){

  const {name, description, price, imageUrl, _id} = product.productProp

  return(
    <div className='cardSize'>
    <Row className="mt-1 mb-1">
      <h2 className='text-danger'>{name}</h2>
      <Col>
        <Card>
          {/* <CardImg variant="top" src="" /> */}
          <Card.Body>
            {/* <Card.Title>{name}</Card.Title> */}
            <Card.Subtitle>Description:</Card.Subtitle>
            <Card.Text>{description}</Card.Text>
            <Card.Subtitle>Price:</Card.Subtitle>
            <br></br>
            {/* <Card.Text>&#8369; {price}</Card.Text> */}
            
            <Link className="btn btn-primary" to={`/product/${_id}`}>Details</Link>

            <Card.Subtitle className='pt-3'>Image</Card.Subtitle>
            <CardImg width="50%" height="50%" top src={imageUrl}/>
          </Card.Body>
        </Card>
      </Col>
      </Row>
    </div>
  )
}
