import React from 'react';

import { Form, Button } from 'react-bootstrap';
// import {useParams, useNavigate, Link} from 'react-router-dom';
import {Link} from 'react-router-dom';
import UserContext from '../UserContext';
import {useState, useEffect, useContext} from 'react';


export default function Helpdesk() {

	const {user} = useContext(UserContext)

	const [isActive, setIsActive] = useState(false)

	const [description, setDescription] = useState('')

	useEffect(() => {
        if((description !== '')){
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [description])

    return (
    <>
        <div rows="4" cols="50">
        <h2 className="profile-name p-1 text-primary">How can we help you today?</h2>
        {/* <Form onSubmit={event => authenticate(event)}> */}
			<Form >
                <Form.Group controlId="description">
					<Form.Text className="text-muted">
                        Type your concerns here.
                    </Form.Text>
                    <Form.Control 
                        type="description" 
                        value={description}
						onChange={event => setDescription(event.target.value)}
                        // onChange={e}
                        required
                    /> 
                </Form.Group>
                <br></br>
				{   isActive ?
                    <Button variant="danger" type="submit" id="submitBtn">
                        Submit
                    </Button>
                    :
                    <Button variant="danger" type="submit" id="submitBtn" disabled>
                        Submit
                    </Button>
                }
			</Form>	
		<p className='text-primary'>Go back to the <Link to= {"/"} >homepage.</Link></p>
        </div>
    </>
    )
}