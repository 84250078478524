import React from 'react';
import {Card, Col} from 'react-bootstrap';
import UserContext from '../UserContext';
import {useContext} from 'react';

export default function TransactionHistoryCard({transaction}){

  const {user} = useContext(UserContext)

  return(
    <div className='pb-5'>
      <Col xs={12} md={6}>
        <Card>
          <Card.Body>
          { (transaction.transferredBy === user.id) ?
            <>
              <Card.Title>Buy</Card.Title>
              <Card.Text><strong>Requested By:</strong> {transaction.transferredToName}</Card.Text>
              <Card.Text><strong>Diamond Amount:</strong> {transaction.diaAmount}</Card.Text>
              <Card.Text><strong>Loader Transaction:</strong> {(transaction.agentToAgent? "Yes" : "No")}</Card.Text>
              <Card.Text><strong>Approved:</strong> {(transaction.isApproved? "Yes" : "No")}</Card.Text>
              <Card.Text><strong>Date:</strong> {transaction.timeStamp}</Card.Text>
            </>
            :
            <>
              <Card.Title>Trade</Card.Title>
              <Card.Text><strong>Requested By:</strong> {transaction.transferredByName}</Card.Text>
              <Card.Text><strong>Diamond Amount:</strong> {transaction.diaAmount}</Card.Text>
              <Card.Text><strong>Loader Transaction:</strong> {(transaction.agentToAgent? "Yes" : "No")}</Card.Text>
              <Card.Text><strong>Approved:</strong> {(transaction.isApproved? "Yes" : "No")}</Card.Text>
              <Card.Text><strong>Date:</strong> {transaction.timeStamp}</Card.Text>
            </>
            }
          </Card.Body>
        </Card>
      </Col>
    </div>
  )
}
