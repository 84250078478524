import React from 'react';
import {useState, useEffect, useContext} from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';

import Swal from 'sweetalert2';

export default function SellDiamondView() {

    const {user} = useContext(UserContext)

    const navigate = useNavigate()

    const {diamondId, profileId} = useParams()

    const [userId, setUserId] = useState('')

    const [diamond, setDiamond] = useState('')

    const [isActive, setIsActive] = useState(false)

    const setPlayerDiamond = (diamondId, profileId) => {
		fetch(`${process.env.REACT_APP_API_URL}/users/sell-diamond/${diamondId}/${profileId}`, {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
                diamond: diamond
			})
		})
		.then(response => response.json())
		.then(result => {
			if(result) {
				Swal.fire({
					title: "Success!",
					icon: "success",
					text: "Diamond requested to Loader!"
				})
                
                navigate('/');
                //Window Reload 03-11-24
                // window.location.reload();
                
			} else {
				console.log(result)

				Swal.fire({
					title: "Something went wrong!",
					icon: "error",
					text: "Please try again."
				})
			}
		})
	}

    function setRequestDiamond(event){
        event.preventDefault()
        setPlayerDiamond(diamondId, profileId);
    }

    useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/profile/${user.userId}`)
		.then(response => response.json())
		.then(result => {
			console.log(result.username)
			console.log(result.email)
			setUserId(result.userId)
		})
	}, [userId])

    useEffect(() => {
        if( (parseInt(diamond) !== 0 && parseInt(diamond) % 10 === 0 && parseInt(diamond) >= 10) ){
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [diamond])

    return (
        <Row className="mt-4 mb-4">
            <Col>
                <h2 className='text-black'>Trade Diamond to Loader</h2>
                <p className='text-primary'>Set Diamond here :</p>
                    <Form onSubmit={event => setRequestDiamond(event)}>
                        <Form.Group controlId="setRequestDiamond">
                            <Form.Control 
                                type="number" 
                                placeholder="Enter number of diamonds to trade"
                                value={diamond}
                                onChange={event => setDiamond(event.target.value)}
                                required
                            />
                        </Form.Group>
                        <br></br>
                        {   isActive ?
                            <Button variant="danger" type="submit" id="submitBtn">
                                Trade
                            </Button>
                            :
                            <Button variant="danger" type="submit" id="submitBtn" disabled>
								Trade
                            </Button>
                        }
                    </Form>
               
                <p className='text-primary'>Go back to the <Link to= {"/"} >homepage.</Link></p>
            </Col>
        </Row>
    )
}