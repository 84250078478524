
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
    return (
        <Row>
            <Col>
                <h1 className='text-primary'>Coming Soon</h1>
                <p className='text-primary'>Go back to the <Link to= {"/"} >homepage.</Link></p>
                
            </Col>
        </Row>
    )
}