import React from 'react';
import {useState, useEffect} from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';


export default function SetReferralLink() {

    const navigate = useNavigate()

    const {profileId} = useParams()

    const [referralCode, setReferralCode] = useState('')

    const [isActive, setIsActive] = useState(false)

    const setLinkCode = (profileId) => {
		fetch(`${process.env.REACT_APP_API_URL}/users/setReferralLink`, {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
				userId: profileId,
				referral_code: referralCode
			})
		})
		.then(response => response.json())
		.then(result => {
			if(result) {
				Swal.fire({
					title: "Success!",
					icon: "success",
					text: "Referral Code set!"
				})
                
                navigate('/');
                window.location.reload();

			} else {
				console.log(result)

				Swal.fire({
					title: "Something went wrong!",
					icon: "error",
					text: "Please try again."
				})
			}
		})
	}

    function setCode(event){
        event.preventDefault()
        setLinkCode(profileId);
    }

    useEffect(() => {
        if( ( referralCode.length !== 0 && referralCode.length <= 11 ) ){
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [referralCode])

    return (
        <Row className="mt-4 mb-4">
            <Col>
                <h2 className='text-black'>Set Referral Code</h2>
                <p className='text-primary'>Place your own referral code here :</p>
                    <Form onSubmit={event => setCode(event)}>
                        <Form.Group controlId="setCode">
                            <Form.Control 
                                type="text" 
                                placeholder="Enter your own referral code maximum of 11 alphanumeric characters."
                                value={referralCode}
                                onChange={event => setReferralCode(event.target.value)}
                                required
                            />
                        </Form.Group>
                        <br></br>
                        {   isActive ?
                            <Button variant="danger" type="submit" id="submitBtn">
                                Set Code
                            </Button>
                            :
                            <Button variant="danger" type="submit" id="submitBtn" disabled>
                                Set Code
                            </Button>
                        }
                    </Form>
               
                <p className='text-primary'>Go back to the <Link to= {"/"} >homepage.</Link></p>
            </Col>
        </Row>
    )
}