import React from 'react';

import {Row, Card} from 'react-bootstrap';
import Loading from '../components/Loading';
// import {useParams, useNavigate, Link} from 'react-router-dom';
import ChickenCard from '../components/ChickenCard';
import ItemCard from '../components/ItemCard';

import {Link} from 'react-router-dom';
import UserContext from '../UserContext';
import {useState, useEffect, useContext} from 'react';


export default function Chickens() {

	const {user} = useContext(UserContext)

	const [chickens, setChickens] = useState([])

	const [items, setItems] = useState([])
	
	const [isLoading, setIsLoading] = useState(true)

	// const [isActive, setIsActive] = useState([])

	useEffect(() => {

		fetch(`${process.env.REACT_APP_API_URL}/chicken/`, {
			method: "GET", 
			headers: {
				Authorization: `Bearer ${ localStorage.getItem('token') }`,
			},
		})
		.then(response => response.json())
		.then(result => {
			console.log(result)
			setChickens(
				result.map(chicken => {
					return (
						<ChickenCard key={chicken._id} chickenProp ={chicken}/>
					)
				})
			)
			setIsLoading(false)
		})
	}, [])

	useEffect(() => {

		fetch(`${process.env.REACT_APP_API_URL}/item/`, {
			method: "GET", 
			headers: {
				Authorization: `Bearer ${ localStorage.getItem('token') }`,
			},
		})
		.then(response => response.json())
		.then(result => {
			console.log(result)
			setItems(
				result.map(item => {
					return (
						<ItemCard key={item._id} itemProp ={item}/>
					)
				})
			)
			setIsLoading(false)
		})
	}, [])

    return (
    	(isLoading) ? 
        	<Loading /> 
		:
		<>
			<h2 className="profile-name p-1 text-primary">{user.username}’s Farm</h2>
			
			<br></br>
			<h4 className="profile-list p-1 text-danger">List of Chickens</h4>
			<div className='cardChicken mb-3'>
				{chickens}
			</div>

			<h4 className="profile-list p-1 text-danger">List of Items</h4>
			<div className='cardChicken mb-3'>
				{items}
			</div>
		</>
    )
}