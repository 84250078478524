import React from 'react';
import {useState, useEffect, useContext} from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';

import Swal from 'sweetalert2';


export default function RequestAgentDiaView() {

    const {user} = useContext(UserContext)

    const navigate = useNavigate()

    const [userId, setUserId] = useState('')

    const [setagentdiamond, setAgentDiamond] = useState('')

    const [isActive, setIsActive] = useState(false)

    const setDiamond = () => {
		fetch(`${process.env.REACT_APP_API_URL}/users/request-agentdiamond`, {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
                setagentdiamond: setagentdiamond
			})
		})
		.then(response => response.json())
		.then(result => {
			if(result) {
				Swal.fire({
					title: "Success!",
					icon: "success",
					text: "Diamond requested to Loader!"
				})
                navigate('/agent');
			} else {
				console.log(result)

				Swal.fire({
					title: "Something went wrong!",
					icon: "error",
					text: "Please try again."
				})
			}
		})
	}

    function setRequestDiamond(event){
        event.preventDefault()
        setDiamond();
    }

    useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/profile/${user.userId}`)
		.then(response => response.json())
		.then(result => {
			console.log(result.username)
			console.log(result.email)
			setUserId(result.userId)
		})
	}, [userId])

    useEffect(() => {
        if( ( parseInt(setagentdiamond) !== 0 && parseInt(setagentdiamond) % 10 === 0 && parseInt(setagentdiamond) >= 10) ){
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [setagentdiamond])

    return (
        <Row className="mt-4 mb-4">
            <Col>
                <h2 className='text-black'>Request Diamond to Loader</h2>
                <p className='text-primary'>Set Requested Diamond here :</p>
                    <Form onSubmit={event => setRequestDiamond(event)}>
                        <Form.Group controlId="setRequestDiamond">
                            <Form.Control 
                                type="number" 
                                placeholder="Enter number of requested diamond"
                                value={setagentdiamond}
                                onChange={event => setAgentDiamond(event.target.value)}
                                required
                            />
                        </Form.Group>
                        <br></br>
                        {   isActive ?
                            <Button variant="danger" type="submit" id="submitBtn">
                                Request
                            </Button>
                            :
                            <Button variant="danger" type="submit" id="submitBtn" disabled>
								Request
                            </Button>
                        }
                    </Form>
               
                <p className='text-primary'>Go back to the <Link to= {"/"} >homepage.</Link></p>
            </Col>
        </Row>
    )
}