import React from 'react';
// import { useState, useEffect, useContext } from 'react'
import { Row, Col } from 'react-bootstrap';
// import UserContext from '../UserContext'
// import { useContext } from 'react'
// import {useState, useEffect, useContext} from 'react'
// import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'

export default function Banner() {
       
    return (
        <Row className="mt-4 mb-4">
            <Col>
                <h2 className='text-black'>Play Now and Predict</h2>
                {/* <p>Create, Play, Buy Now.</p> */}

 
                {/* <Link className="btn btn-danger btn-block" to={"/product"}>Buy Now!</Link> */}
                {/* <Button variant="danger">Shop Now!</Button> */}
            </Col>
        </Row>
    )
}