import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function Loading() {
  return (
    <>
      <Spinner className="spinner" animation="grow" variant="warning" />
    </>
  )
}

