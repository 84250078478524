import React from 'react';
import Loading from '../components/Loading';
import { Button } from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';
import UserContext from '../UserContext';
import DiamondCard from '../components/DiamondCard';


export default function Diamonds(){
	const { user } = useContext(UserContext)
    const [diamonds, setDiamonds] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredDiamonds, setFilteredDiamonds] = useState([]);
    const [searchMessage, setSearchMessage] = useState('');

	useEffect(() => {
        fetchDiamonds();
    }, []);

	const fetchDiamonds = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/diamond/getAllDiamonds`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setDiamonds(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching diamonds:', error);
        }
    };
	
	const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

	const handleSearch = () => {
        filterResults();
    };

	const filterResults = () => {
        const searchTermLower = searchTerm.toLowerCase();

        let diamondResults = diamonds; // Initially set to all profiles

        if (searchTerm.trim() !== '') {
            diamondResults = diamonds.filter((diamond) =>
                Object.values(diamond).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            if (diamondResults.length === 0) {
                setSearchMessage('Keyword not found');
            } else {
                setSearchMessage('');
            }
        } else {
            setSearchMessage('');
        }

        setFilteredDiamonds(diamondResults);
    };

	return(			
		isLoading ? <Loading/>
		:
		<>
			{(!user.agent) ?
			<>
				<h3 className="profile-list pt-3 pb-3">Buy/Trade Diamonds</h3>
				<div className="search-bar">
					<input
						type="text"
						placeholder="Search by username, first name, last name, email, mobile no., etc..."
						value={searchTerm}
						onChange={handleSearchInputChange}
					/>
					&nbsp;
					<Button onClick={handleSearch}>Search</Button>
					<p>{searchMessage}</p>
				</div>
				<div className='cardChicken mb-3'>
					{filteredDiamonds.map((diamond) => (
						<DiamondCard key={diamond._id} diamond={diamond} />
					))}
				</div>
			</>
			:
			<>
			<h3 className="profile-list pt-3 pb-3">Buy or Trade Diamonds</h3>
			<div className='cardChicken mb-3'>
				{diamonds.map((diamond) => (
                    <DiamondCard key={diamond._id} diamond={diamond} />
                ))}
			</div>
			</>
			}
		</>
	)
}
