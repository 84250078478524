import React from 'react';
import ProductCard from '../components/ProductCard';

import Loading from '../components/Loading'
import {useEffect, useState, useContext} from 'react'
import UserContext from '../UserContext'

export default function Products(){

	const {user} = useContext(UserContext)

	const [products, setProducts] = useState([])

	const [isLoading, setIsLoading] = useState(true)

	const [isActive, setIsActive] = useState([])

	useEffect((isLoading) => {

		fetch(`${process.env.REACT_APP_API_URL}/product/`)
		.then(response => response.json())
		.then(result => {
			console.log(result)
			setProducts(
				result.map(product => {
					return (
						(isActive) ?
						<ProductCard key={product._id} productProp ={product}/>
						:
						<></>
					)
				})
			)
			setIsLoading(false)
		})
	}, [])

	return(			
			
			(isLoading) ?
				<Loading/>
			:
			<>
				<h1 className="pt-3 pb-3">Buy Now</h1>
				<div className='cardChicken mb-3'>
					{products}
				</div>
				
			</>
	)
}
