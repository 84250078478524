import React from 'react';
import Banner from '../components/Banner';
import Highlights from '../components/Highlights';
import HomeDiamonds from './HomeDiamonds';

export default function Home() {
    return (
    <>
        <Banner/>
        <HomeDiamonds/>
        <Highlights/>
    </>
    )
}