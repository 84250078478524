import React from 'react';
// import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {Form, Button} from 'react-bootstrap';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';

export default function UpdateDiamond() {
	const navigate = useNavigate()
	const [name, setName] = useState('');
	const [description, setDescription] = useState('')
	const [diamond_location, setDiamondLocation] = useState('')
	const [mobileNo, setMobileNo] = useState('')

	const handleSubmit = async e => {
		e.preventDefault();
		try {
		  const response = await fetch(`${process.env.REACT_APP_API_URL}/diamond/updateDiamond`, {
			method: 'POST',
			headers: {
				'Content-Type' : 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				name: name,
				description: description,
				diamond_location: diamond_location,
				mobileNo: mobileNo,
			})
		  });

		  const data = await response.json();
		  if (response.ok) {
			console.log(data.message);
			// Show success message
			Swal.fire({
				title: 'User profile updated successfully!',
				icon: 'success',
				text: data.message
			});
			navigate('/agent');
			// Optionally, redirect the user to another page after successful registration
		  } else {
			console.error('Error updating user:', data.message);
			// if (data.message === 'Username already exists') {
			// 	// Show alert for duplicate username
			// 	Swal.fire({
			// 		icon: 'error',
			// 		title: 'Oops...',
			// 		text: 'Username already exists in the database. Please use a different username.'
			// 	});
			if (data.message === 'Diamond name already exists') {
				// Show alert for duplicate email
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Diamond name already exists in the database. Please use a different name.'
				});
			} else {
				// Show error message
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'An error occurred. Please try again later.'
				});
			}
		  }
		} catch (error) {
		  console.error('Error registering user:', error.message);
		  // Show error message
		  Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'Something went wrong!'
		  });
		}
	};

	return (
		<>
		<h4>Update Diamond Info</h4>
		<Form onSubmit={e => handleSubmit(e)}>
				<Form.Group controlId="name">
			        <Form.Label className='text-primary'>Diamond Name</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change Diamond Name"
							name="name"
				            value={name} 
				            onChange={e => setName(e.target.value)}
				        />
			    </Form.Group>

				<Form.Group controlId="description">
			        <Form.Label className='text-primary'>Description</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change Description"
							name="description"
				            value={description} 
				            onChange={e => setDescription(e.target.value)}
				        />
			    </Form.Group>

			    <Form.Group controlId="diamond_location">
			        <Form.Label className='text-primary'>Diamond Location</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change Location"
							name="diamond_location"
				            value={diamond_location} 
				            onChange={e => setDiamondLocation(e.target.value)}
				        />
			    </Form.Group>

			    <Form.Group controlId="mobileNo">
			        <Form.Label className='text-primary'>Mobile Number</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Change Mobile Number"
							name="mobileNo"
				            value={mobileNo} 
				            onChange={e => setMobileNo(e.target.value)}
				        />
			    </Form.Group>

	            {/* <Form.Group controlId="password">
	            <Form.Label className='text-primary'>Password</Form.Label>
	                <Form.Control 
		                type={showPassword ?  "text" : "password"} 
		                placeholder="Change Password"
						name="password"
		                value={password} 
				        onChange={e => setPassword(e.target.value)}
	                />
					<Button
						onClick={togglePasswordVisibility}>
						{showPassword ? <FaEyeSlash /> : <FaEye />}
					</Button>
					<Form.Text className="text-muted">
			            Never share your password with anyone else.
			        </Form.Text>
	            </Form.Group> */}

				<br></br>
				<Button variant="danger" type="submit" id="submitBtn">
			        Submit
			    </Button>
			</Form>
		</>
	)
}