import React from 'react';
import {Card, CardImg, Col, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'

export default function ItemCard(item){

  const {name, description, levelIncrease, price, imageUrl, product_location, expiryPeriod, appliedAt, _id} = item.itemProp

  return(
    <div className='cardSize'>
      <Row className="mt-1 mb-1">
        <h4 className='text-primary'>{name}</h4>
        <Col className=''>
          <Card className=''>
            {/* <CardImg variant="top" src="" /> */}
            <Card.Body className=''>
              {/* <Card.Subtitle>Description:</Card.Subtitle> */}
              <Card.Text>Description: {description}</Card.Text>

              <Card.Text>Location: {product_location}</Card.Text>

              <Card.Text>Price: &#8369; {price}</Card.Text>

              {/* <Card.Text>Selected: {isSelected}</Card.Text> */}
              <Card.Subtitle className='pt-3'>Image</Card.Subtitle>
              <CardImg width="50%" height="50%" top src={imageUrl}/>

              <Card.Text>Level Increase: {levelIncrease}</Card.Text>

              <Card.Text>Level Plus Duration: {expiryPeriod/60} min/s</Card.Text>

              <Card.Text>Item Applied On: {appliedAt}</Card.Text>

              <Link className="btn btn-primary" to={`/item/${_id}`}>Details</Link>

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
