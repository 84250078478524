import React from "react";
import Policy from '../components/Policy'; 
// import { Navigate } from "react-router-dom";

export default function PrivacyPolicy() {
    return (
    <>
        <Policy />   
    </>
    )
};