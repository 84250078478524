import React from 'react';
import {Card, CardImg, Col, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'

export default function ChickenCard(chicken){

  const {name, description, price, imageUrl, product_location, chickenlevel, chickenexp, health, strength, defense, vitality, stamina, critical, _id} = chicken.chickenProp

  return(
    <div className='cardSize'>
      <Row className="mt-1 mb-1">
        <h4 className='text-primary'>{name}</h4>
        <Col>
          <Card>
            {/* <CardImg variant="top" src="" /> */}
            <Card.Body className=''>
              {/* <Card.Subtitle>Description:</Card.Subtitle> */}
              <Card.Text>Description: {description}</Card.Text>

              <Card.Text>Location: {product_location}</Card.Text>

              <Card.Text>Price: &#8369; {price}</Card.Text>

              {/* <Card.Text>Selected: {isSelected}</Card.Text> */}
              <Card.Subtitle className='pt-3'>Image</Card.Subtitle>
              <CardImg width="50%" height="50%" top src={imageUrl}/>

              <Card.Text>Level: {chickenlevel}</Card.Text>

              <Card.Text>Exp: {chickenexp}</Card.Text>

              <Card.Text>HEALTH: {health}</Card.Text>

              <Card.Text>STRENGTH: {strength}</Card.Text>

              <Card.Text>DEFENSE: {defense}</Card.Text>

              <Card.Text>VITALITY: {vitality}</Card.Text>

              <Card.Text>STAMINA: {stamina}</Card.Text>

              <Card.Text>CRITICAL: {critical}</Card.Text>
              
              <Link className="btn btn-primary" to={`/chicken/${_id}`}>Details</Link>

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
