import PredictionCard from '../components/PredictionCard';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import Loading from '../components/Loading';
// import UserContext from '../UserContext';


export default function Prediction() {
    // const { user } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [matches, setMatches] = useState([]);

    const [filteredMatches, setFilteredMatches] = useState([]);
    const [searchMessage, setSearchMessage] = useState('');

    useEffect(() => {
        fetchMatches();
    }, []);

    const fetchMatches = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/match/getOngoingMatches`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setMatches(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching matches:', error);
        }
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

	const handleSearch = () => {
        filterResults();
    };

	const filterResults = () => {
        const searchTermLower = searchTerm.toLowerCase();

        let matchResults = matches; // Initially set to all matches

        if (searchTerm.trim() !== '') {
            matchResults = matches.filter((match) =>
                Object.values(match).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTermLower)
                )
            );

            if (matchResults.length === 0) {
                setSearchMessage('Keyword not found');
            } else {
                setSearchMessage('');
            }
        } else {
            setSearchMessage('');
        }

        setFilteredMatches(matchResults);
    };

    return (
        isLoading ? <Loading /> :
        <>
            <h4 className='text-primary'>Predict Now and Win!</h4>
            <br />
				<div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search by username, first name, last name, email, mobile no., etc..."
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
					&nbsp;
                    <Button onClick={handleSearch}>Search</Button>
                    <p>{searchMessage}</p>
                </div>
			<br />
            <div>
                {filteredMatches.map((match) => (
                        <PredictionCard key={match._id} match={match} />
                    ))}
            </div>
        </>
    )
}