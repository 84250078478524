import React from 'react';
import './App.css';

import { useState, useEffect } from 'react';
import { UserProvider } from './UserContext';

import AppNavbar from './components/AppNavbar';

import Home from './pages/Home';
//Admin
import AdminDashboard from './pages/AdminDashboard';
//Agent
import AgentDashboard from './pages/AgentDashboard';
import PlayerDiaTransferView from './components/PlayerDiaTransferView';
import AgentDiaTransferView from './components/AgentDiaTransferView';
import PlayerToAgentView from './components/PlayerToAgentView';
import SetAgentDiamond from './components/SetAgentDiamond';

import SetReferralLink from './components/SetReferralLink';

//Products
import Products from './pages/Products';
import ProductView from './components/ProductView';

//Diamond
// import AgentDiamonds from './pages/AgentDiamonds';
import Diamonds from './pages/Diamonds';
import DiamondView from './components/DiamondView';
import RequestAgentDiaView from './components/RequestAgentDiaView';
import SellDiamondView from './components/SellDiamondView';
import SellAgentDiaView from './components/SellAgentDiaView';
import UpdateDiamond from './pages/UpdateDiamond';

//Transactions
//Sell
import SellPlayerTransactionView from './components/SellPlayerTransactionView';
import AgentTransactionView from './components/AgentTransactionView';
//Buy
import BuyAgentTransactionView from './components/BuyAgentTransactionView';
import TransactionView from './components/TransactionView';

import TransactionHistory from './pages/TransactionHistory';

//Users
import Register from './pages/Register';
import Login from './pages/Login';
import Logout from './pages/Logout';
import UpdateUser from './pages/UpdateUser';
import ErrorPage from './pages/ErrorPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Profiles from './pages/Profiles';
import Chickens from './pages/Chickens';

//Match and Betting
// import MatchView from './components/MatchView';
// import MatchCard from './components/MatchCard';

import Matches from './pages/Matches';
import Prediction from './pages/Prediction';

import MatchPredict from './components/MatchPredict';

import PlaceBet from './components/PlaceBet';

import Helpdesk from './pages/Helpdesk';

import PreFooter from './components/PreFooter';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Footer from './components/Footer';
// import GoToTopLink from './components/GoToTopLink';

function App() {

  const [user, setUser] = useState({
          id: null,
          googleId: null,
          facebookId: null,
          gameId: null,
          username: null,
          //
          firstName: null,
          lastName: null,
          currentBet: null,
          elo: null,
          //
          email: null,
          mobileNo: null,
          gold: null,
          diamond: null,
          agentdiamond: null,
          agentpercentage: null,
          earnagentdiamond: null,
          setagentdiamond: null,
          earndiamond: null,
          playerlevel: null,
          levelplus: null,
          chickencount: null,
          exp: null,
          win: null,
          loss: null,
          isAdmin: null,
          //
          isSuperAdmin: null,
          isAgent: null,
          isSubAgent: null,
          subAgentCategory: null,
          isActive: null,
          isOnline: null,
          //
          chickens: null,
          items: null,

          agent: null,
          referral_code: null,
          parent_referral_code: null
  })

  const unsetUser = () => {
    localStorage.clear();
    setUser({
          id: null,
          googleId: null,
          facebookId: null,
          gameId: null,
          username: null,
          //
          firstName: null,
          lastName: null,
          currentBet: null,
          elo: null,
          //
          email: null,
          mobileNo: null,
          gold: null,
          diamond: null,
          agentdiamond: null,
          agentpercentage: null,
          earnagentdiamond: null,
          setagentdiamond: null,
          earndiamond: null,
          playerlevel: null,
          levelplus: null,
          chickencount: null,
          exp: null,
          win: null,
          loss: null,
          isAdmin: null,
          //
          isSuperAdmin: null,
          isAgent: null,
          isSubAgent: null,
          subAgentCategory: null,
          isActive: null,
          isOnline: null,
          //
          chickens: null,
          items: null,

          agent: null,
          referral_code: null,
          parent_referral_code: null
    })
  }

  useEffect(() => {

      fetch(`${ process.env.REACT_APP_API_URL }/users/getDetail`, {
        headers: {
          Authorization: `Bearer ${ localStorage.getItem('token') }`
        }
      })
      .then(res => res.json())
      .then(data => {
        if (typeof data._id !== "undefined") {
          setUser({
            id: data._id,
            googleId: data.googleId,
            facebookId: data.facebookId,
            gameId: data.gameId,
            username: data.username,

            firstName: data.firstName,
            lastName: data.lastName,
            currentBet: data.currentBet,
            elo: data.elo,

            email: data.email,
            mobileNo: data.mobileNo,
            gold: data.gold,
            diamond: data.diamond,
            agentdiamond: data.agentdiamond,
            agentpercentage: data.agentpercentage,
            earnagentdiamond: data.earnagentdiamond,
            setagentdiamond: data.setagentdiamond,
            earndiamond:  data.earndiamond,
            playerlevel: data.playerlevel,
            levelplus:  data.levelplus,
            chickencount: data.chickencount,
            exp: data.exp,
            win: data.win,
            loss: data.loss,
            isAdmin: data.isAdmin,

            isSuperAdmin: data.isSuperAdmin,
            isAgent: data.isAgent,
            isSubAgent: data.isSubAgent,
            subAgentCategory: data.subAgentCategory,
            isActive: data.isActive,
            isOnline: data.isOnline,

            chickens: data.chickens,
            items: data.items,

            agent: data.agent,
            referral_code: data.referral_code,
            parent_referral_code: data.parent_referral_code
          });
        } else {
          setUser({
            id: null,
            googleId: null,
            facebookId: null,
            gameId: null,
            username: null,
            //
            firstName: null,
            lastName: null,
            currentBet: null,
            elo: null,
            //
            email: null,
            mobileNo: null,
            gold: null,
            diamond: null,
            agentdiamond: null,
            agentpercentage: null,
            earnagentdiamond: null,
            setagentdiamond: null,
            earndiamond: null,
            playerlevel: null,
            levelplus: null,
            chickencount: null,
            exp: null,
            win: null,
            loss: null,
            isAdmin: null,
            //
            isSuperAdmin: null,
            isAgent: null,
            isSubAgent: null,
            subAgentCategory: null,
            isActive: null,
            isOnline: null,
            //
            chickens: null,
            items: null,

            agent: null,
            referral_code: null,
            parent_referral_code: null
          });
        }
      })
  }, []);

  return (
    <>
      <div className="background-image">
      <UserProvider value={{user, setUser, unsetUser}}>
      {/* <img src="images/background-gradient.jpg" alt="bg-pic"/> */}
        <Router>
        {/* <img src={bg_picture} alt="bg-pic"/> */}
          <AppNavbar/>
          <Container>
            <Routes>
              {/* <Route path= {`${process.env.REACT_APP_HOST_SITE}/`} element={<Home/>}/>
              <Route path= {`${process.env.REACT_APP_HOST_SITE}/product`} element={<Products/>}/>
              <Route path= {`${process.env.REACT_APP_HOST_SITE}/profile`} element={<Profiles/>}/>
              <Route path= {`${process.env.REACT_APP_HOST_SITE}/profile/:profileId`} element={<PlaceBet/>}/>
              <Route path= {`${process.env.REACT_APP_HOST_SITE}/product/:productId`} element={<ProductView/>}/>
              <Route path= {`${process.env.REACT_APP_HOST_SITE}/admin`} element={<AdminDashboard/>}/>
              <Route path= {`${process.env.REACT_APP_HOST_SITE}/agent`} element={<AgentDashboard/>}/>
              <Route path= {`${process.env.REACT_APP_HOST_SITE}/login`} element={<Login/>}/>
              <Route path= {`${process.env.REACT_APP_HOST_SITE}/register`} element={<Register/>}/>
              <Route path= {`${process.env.REACT_APP_HOST_SITE}/logout`} element={<Logout/>} />
              <Route path= {`${process.env.REACT_APP_HOST_SITE}/helpdesk`} element={<Helpdesk/>} />
              <Route path= {`${process.env.REACT_APP_HOST_SITE}/match`} element={<Matches/>} />
              
              <Route path= {`${process.env.REACT_APP_HOST_SITE}*`} element={<ErrorPage/>}/> */}

              {/* <Route path= "https://fowloffury.com/" element={<Home/>}/>
              <Route path= "https://fowloffury.com/product" element={<Products/>}/>
              <Route path= "https://fowloffury.com/profile" element={<Profiles/>}/>
              <Route path= "https://fowloffury.com/profile/:profileId" element={<PlaceBet/>}/>
              <Route path= "https://fowloffury.com/product/:productId" element={<ProductView/>}/>
              <Route path= "https://fowloffury.com/admin" element={<AdminDashboard/>}/>
              <Route path= "https://fowloffury.com/agent" element={<AgentDashboard/>}/>
              <Route path= "https://fowloffury.com/login" element={<Login/>}/>
              <Route path= "https://fowloffury.com/register" element={<Register/>}/>
              <Route path= "https://fowloffury.com/logout" element={<Logout/>} />
              <Route path= "https://fowloffury.com/match" element={<MatchView/>} />
              <Route path= "https://fowloffury.com/predict" element={<Predict/>} />
              <Route path= "https://fowloffury.com*" element={<ErrorPage/>}/> */}

              <Route path= "/login" element={<Login/>}/>
              <Route path= "/register/:agentId?" element={<Register/>}/>
              <Route path= "/" element={<Home/>}/>
              {/* //------Non-Player Prediction---------// */}
              <Route path= "/predict" element={<Prediction/>}/>
              <Route path= "/predict/:profileId" element={<MatchPredict/>}/>
              {/* // */}
              <Route path= "/product" element={<Products/>}/>
              <Route path= "/chicken" element={<Chickens/>}/>
              <Route path= "/profile" element={<Profiles/>}/>
              <Route path= "/editprofile" element={<UpdateUser/>}/>
              <Route path= "/editdiamond" element={<UpdateDiamond/>}/>
              <Route path= "/player/:agentId/:profileId" element={<PlayerDiaTransferView/>}/>
              <Route path= "/playertoagent/:agentId/:profileId" element={<PlayerToAgentView/>}/>
              
              <Route path= "/agent/:agentId" element={<SetAgentDiamond/>}/>
              <Route path= "/agent/:agentId/:profileId" element={<AgentDiaTransferView/>}/>
              <Route path= "/profile/:profileId" element={<PlaceBet/>}/>
              <Route path= "/product/:productId" element={<ProductView/>}/>

              {/* <Route path= "/agentdiamond" element={<AgentDiamonds/>}/> */}
              <Route path= "/diamond" element={<Diamonds/>}/>
              {/* //BUY Diamonds Player to Agent */}
              <Route path= "/buy-diamond/:diamondId/:profileId" element={<DiamondView/>}/>
              {/* //BUY Diamonds Agent to Agent */}
              <Route path= "/request-agentdiamond" element={<RequestAgentDiaView/>}/>
              {/* //SELL Diamonds Player to Agent */}
              <Route path= "/sell-diamond/:diamondId/:profileId" element={<SellDiamondView/>}/>
              {/* //SELL Diamonds Agent to Agent */}
              <Route path= "/sell-agentdiamond" element={<SellAgentDiaView/>}/>

              <Route path= "/setreferrallink/:profileId" element={<SetReferralLink/>}/>
              {/* //TRANSACTION */}
              {/* //Buy */}
              <Route path= "/transaction/:transactionId/:profileId" element={<TransactionView/>}/>
              <Route path= "/transaction/buydiamondagent/:transactionId/:profileId" element={<BuyAgentTransactionView/>}/>
              {/* //Sell */}
              <Route path= "/transaction/playertransaction/:transactionId/:profileId" element={<SellPlayerTransactionView/>}/>
              <Route path= "/transaction/agenttransaction/:transactionId/:profileId" element={<AgentTransactionView/>}/>


              <Route path= "/transactionhistory" element={<TransactionHistory/>}/>

              <Route path= "/admin" element={<AdminDashboard/>}/>
              <Route path= "/agent" element={<AgentDashboard/>}/>
              
              <Route path= "/logout" element={<Logout/>} />
              <Route path= "/match" element={<Matches/>} />
              <Route path= "/history" element={<Matches/>} />
              <Route path= "/helpdesk" element={<Helpdesk/>}/>  
              <Route path= "/*" element={<ErrorPage/>}/>
              <Route path= "/privacypolicy" element={<PrivacyPolicy/>}/>
            </Routes>
          </Container>
          {/* <Footer/> */}
          <PreFooter/>
          {/* <GoToTopLink/> */}
        </Router>
      </UserProvider>
      </div>
    </>
  );
}

export default App;
