import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {Form, Button} from 'react-bootstrap';
import {useState, useContext, useEffect} from 'react';
import {useNavigate, Navigate, useParams} from 'react-router-dom';
import UserContext from '../UserContext';
import Swal from 'sweetalert2';
import "../styles/Login.css";

export default function Register() {

	const {user} = useContext(UserContext)
	const navigate = useNavigate()

	const { agentId } = useParams();

	const [username, setUsername] = useState('');
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [mobileNo, setMobileNo] = useState('')

	const [email, setEmail] = useState('')
	const [parentReferralCode, setParentReferralCode] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')

	const [isActive, setIsActive] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	useEffect(() => {
		if (agentId) {
		  fetch(`${process.env.REACT_APP_API_URL}/users/register/${agentId}`)
			.then(response => response.json())
			.then(data => setParentReferralCode(data.agentcode))
			.catch(error => console.error('Error fetching agent:', error));
		}
	  }, [agentId]);

	useEffect(() => {
		if((username !== '' && firstName !== '' && lastName !== '' && mobileNo.length === 11 && email !== '' && password !== '' && confirmPassword !== '') && (password === confirmPassword)){
			// Enables the submit button if the form data has been verified
			setIsActive(true)
		} else {
			setIsActive(false)
		}
	}, [username, firstName, lastName, mobileNo, email, password, confirmPassword]);

	const handleSubmit = async e => {
		e.preventDefault();

		let referralCodeExists = true; // Assume referral code exists by default

        if (parentReferralCode.trim() !== '') {
            // User provided a non-blank referral code, validate it
            referralCodeExists = await validateReferralCode(parentReferralCode);
            if (!referralCodeExists) {
                // Show an error message indicating that the referral code does not exist
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Referral code does not exist in the database. Please use a valid referral code.'
                });
                return; // Exit function early to prevent further processing
            }
        }

		try {
		  const response = await fetch(`${process.env.REACT_APP_API_URL}/users/register`, {
			method: 'POST',
			headers: {
				'Content-Type' : 'application/json'
			},
			body: JSON.stringify({
				username: username,
				firstName: firstName,
				lastName: lastName,
				mobileNo: mobileNo,
				email:email,
				parent_referral_code: parentReferralCode,
				password: password
			})
		  });

		  const data = await response.json();
		  if (response.ok) {
			console.log(data.message);
			// Show success message
			Swal.fire({
				title: 'Registration successful!',
				icon: 'success',
				text: data.message
			});
			navigate('/login');
			// Optionally, redirect the user to another page after successful registration
		  } else {
			console.error('Error registering user:', data.message);
			if (data.message === 'Username already exists') {
				// Show alert for duplicate username
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Username already exists in the database. Please use a different username.'
				});
			// } else {
			} else if (data.message === 'Email already exists') {
				// Show alert for duplicate email
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Email already exists in the database. Please use a different email.'
				});
			} else if (data.message === 'Referral Code does not exist') {
				// Show alert for duplicate email
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Code does not exists in the database. Please use a different code.'
				});
			} else {
				// Show error message
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'An error occurred. Please try again later.'
				});
			}
		  }
		} catch (error) {
		  console.error('Error registering user:', error.message);
		  // Show error message
		  Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'Something went wrong!'
		  });
		}
	};

	const validateReferralCode = async (parentReferralCode) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/validate-referral-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    parent_referral_code: parentReferralCode
                })
            });

            const data = await response.json();
            return response.ok && data.exists; // Return true if referral code exists, false otherwise
        } catch (error) {
            console.error('Error validating referral code:', error.message);
            return false; // Return false if an error occurs
        }
    };

	return (
		(user.id !==null) ?
			<Navigate to= {"/product"} />
		:
		<div className="login-container">
			<div className="login-box">
			<h2>Register</h2>
			<Form onSubmit={e => handleSubmit(e)}>
				<Form.Group controlId="username">
			        <Form.Label className='text-primary'>User Name</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Enter User Name"
							name="username"
				            value={username} 
				            onChange={e => setUsername(e.target.value)}
				            required
				        />
			    </Form.Group>

				<Form.Group controlId="firstName">
			        <Form.Label className='text-primary'>First Name</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Enter First Name"
							name="firstName"
				            value={firstName} 
				            onChange={e => setFirstName(e.target.value)}
				            required
				        />
			    </Form.Group>

			    <Form.Group controlId="lastName">
			        <Form.Label className='text-primary'>Last Name</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Enter Last Name"
							name="lastName"
				            value={lastName} 
				            onChange={e => setLastName(e.target.value)}
				            required
				        />
			    </Form.Group>

			    <Form.Group controlId="mobileNo">
			        <Form.Label className='text-primary'>Mobile Number</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Enter Mobile Number"
							name="mobileNo"
				            value={mobileNo} 
				            onChange={e => setMobileNo(e.target.value)}
				            required
				        />
			    </Form.Group>

			   	<Form.Group controlId="email">
			        <Form.Label className='text-primary'>Email address</Form.Label>
				        <Form.Control 
				            type="email" 
				            placeholder="Enter email"
							name="email"
				            value={email} 
				            onChange={e => setEmail(e.target.value)}
				            required
				        />
			    </Form.Group>

				<Form.Group controlId="parentReferralCode">
			        <Form.Label className='text-primary'>Referral Code - Optional</Form.Label>
				        <Form.Control 
				            type="text" 
				            placeholder="Enter Referral Code"
							name="parentReferralCode"
				            value={parentReferralCode} 
				            onChange={e => setParentReferralCode(e.target.value)}
				            // required
				        />
			    </Form.Group>

	            <Form.Group controlId="password">
	            <Form.Label className='text-primary'>Password</Form.Label>
	                <Form.Control 
		                type={showPassword ?  "text" : "password"} 
		                placeholder="Password"
						name="password"
		                value={password} 
				        onChange={e => setPassword(e.target.value)}
		                required
	                />
					<Form.Text className="text-muted">
			            Never share your password with anyone else.
			        </Form.Text>
	            </Form.Group>

	            <Form.Group controlId="confirmPassword">
	                <Form.Label className='text-primary'>Confirm Password</Form.Label>
						{(password !== confirmPassword) ?
							<Form.Text className="text-danger">
								&nbsp;&nbsp;&nbsp;Password do not match!
			        		</Form.Text>
							:
							<></>
						}
						<Form.Control
							type={showPassword ?  "text" : "password"} 
							placeholder="Confirm Password"
							name="confirmPassword"
							value={confirmPassword}
							onChange={e => setConfirmPassword(e.target.value)}
							required
						/>
						
						<Button
							onClick={togglePasswordVisibility}>
							{showPassword ? <FaEyeSlash /> : <FaEye />}
						</Button>
	            </Form.Group>
				<br></br>
	            {isActive ? 
	            	<button className="login-button" variant="danger" type="submit" id="submitBtn">
			            Register
			        </button>
			        :
			        <button className="login-button1" variant="danger" type="submit" id="submitBtn" disabled>
			            Register
			        </button>
	            }   
			</Form>
			</div>
		</div>
	)
}