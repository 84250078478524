import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';

export default function AdminBanner() {
    return (
        <Row className="mt-3 mb-3">
            <Col>
                <h2>Welcome back Admin</h2>
                <p>Manage your products and users here.</p>

                <Button className="ml-3 mr-3" variant="warning">Add Chicken</Button>
                <br></br>
                <br></br>
                <Button className="ml-3 mr-3" variant="danger">Suspend User Account</Button>
            </Col>
        </Row>
    )
}